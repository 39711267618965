import { getIcon } from "@litonarefin/utils/icons";
import axios from "axios";
import { handleToastr } from "@litonarefin/utils/handleToastr";

function ReactAdminSubscribe({ listID = "", tagID = "" }) {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let name = formData.get("name");
        let email = formData.get("email");
        name = name == null ? "" : name;

        let customFieldValues = {
            site_source: process.env.NEXT_PUBLIC_NEXTJS_SITE_URL,
        };

        let { data } = await axios.post("/api/subscribe/create", {
            first_name: name,
            email: email,
            lists: listID?.replaceAll(" ", "").split(",").map(Number),
            tags: tagID?.replaceAll(" ", "").split(",").map(Number),
            status: "yes",
            custom_values: customFieldValues,
        });

        if (data?.success) {
            let msg = "Subscribe Success!";
            handleToastr(msg, "success", 3000);
        } else {
            handleToastr(data?.message, "warning", 3000);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="jlt-mt-6 jlt-w-full md:jlt-w-[480px] jlt-gap-2 jlt-flex jlt-items-center jlt-justify-center">
            <input
                placeholder="Email Address"
                name="email"
                required={true}
                className="jlt-py-3 jlt-px-[14px] jlt-text-base jlt-placeholder-[#98A2B3] jlt-border jlt-border-[
                    #F2F4F7] focus:jlt-outline-none jlt-w-full"
            />
            <button className="jlt-py-3 jlt-px-5 jlt-rounded jlt-bg-[#FB346D] jlt-text-base jlt-font-semibold jlt-text-white">
                Subscribe
            </button>
        </form>
    );
}

export default ReactAdminSubscribe;
