import { Subscribe } from "@litonarefin/components/Subscribe";
import Image from "next/image";
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "@litonarefin/components/Icons";
import Link from "next/link";

function JewelthemeFooter({ subscribeData, footerOne, footerTwo, footerThree }) {
    const year = new Date().getFullYear();

    return (
        <footer
            className={`jlt-relative jlt-bg-[linear-gradient(125.97deg,_#F8FAFF_-0.08%,_rgba(232,_228,_255,_0)_99.8%)] jlt-pb-12 sm:jlt-pb-16 md:jlt-pb-28 sm:jlt-pt-28 md:jlt-pt-[154px] jlt-bg-cover jlt-bg-center jlt-pt-24`}>
            <Image
                src={"/footer-mash.png"}
                alt="Footer Backgrund"
                fill={true}
                sizes="100vw"
                className="jlt-z-[1]"
            />
            <div className="jlt-container jlt-max-w-[1280px] jlt-mx-auto jlt-px-8 jlt-z-[1] jlt-relative">
                <div className="jlt-grid jlt-gap-y-8 md:jlt-grid-cols-2 lg:jlt-grid-cols-5">
                    <FooterMenu label="Products" menus={footerOne} />
                    <FooterMenu label="Supports" menus={footerTwo} />
                    <FooterMenu label="Company" menus={footerThree} />

                    <div className="jlt-flex jlt-flex-col lg:jlt-col-span-2 jlt-text-center md:jlt-text-left lg:jlt-text-left jlt-items-center md:jlt-items-start lg:jlt-items-start">
                        <Image
                            src={"/logo.svg"}
                            alt={"Footer Logo"}
                            width={224}
                            height={36}
                            sizes={"100vw"}
                        />
                        <p className="jlt-mt-9 jlt-text-2xl jlt-font-bold jlt-color-[#16213E] jlt-mb-4">
                            Subscribe to our newsletter and get all the Discounts and Produfromct
                            Updates
                        </p>

                        <Subscribe listID={subscribeData?.listID} tagID={subscribeData?.tagID} />

                        <div className="jlt-flex jlt-text-center jlt-gap-x-2 jlt-mt-6">
                            <Facebook />
                            <Twitter />
                            <Youtube />
                            <Instagram />
                            <Linkedin />
                        </div>
                    </div>
                </div>
                <div className="jlt-flex jlt-flex-col md:jlt-flex-row jlt-justify-between jlt-items-center jlt-mt-24">
                    <p className="jlt-text-[#ADADBC] jlt-mb-4 md:jlt-mb-0 jlt-text-center md:jlt-text-left">
                        <Link className="jlt-font-bold jlt-text-[#256EFF]" href={"/"}>
                            {process.env.NEXT_PUBLIC_SOURCE_SITE}
                        </Link>
                        <span> &copy; 2014-{year} All rights reserved.</span>
                    </p>
                    <Image
                        src={"/payment.png"}
                        alt="Payment"
                        width={194}
                        height={24}
                        sizes={"100vw"}
                    />
                </div>
            </div>
            {/* <Fomo /> */}
        </footer>
    );
}

export default JewelthemeFooter;

const FooterMenu = ({ label = "", menus }) => {
    return (
        <div className="jlt-flex jlt-flex-col jlt-text-center md:jlt-text-left lg:text-left">
            <h3 className="jlt-text-[#16213E] jlt-text-2xl jlt-font-bold">{label}</h3>
            <div className="jlt-mt-8 ">
                <div className="jlt-flex jlt-flex-col jlt-items-center md:jlt-items-start jlt-gap-4">
                    {menus?.map((item, idx) => (
                        <div key={idx} className="jlt-flex jlt-items-center jlt-gap-2">
                            {item?.img_url ? (
                                <div>
                                    <img
                                        src={item?.img_url}
                                        alt={item?.img_alt || "Product Logo"}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            ) : null}
                            {!!item?.menu_target ? (
                                <a
                                    className="jlt-text-sm jlt-font-semibold jlt-text-[#475467] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}
                                    target="_blank">
                                    {item?.menu_label}
                                </a>
                            ) : (
                                <Link
                                    className="jlt-text-sm jlt-font-semibold jlt-text-[#475467] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}>
                                    {item?.menu_label}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
