import * as React from "react";
const SvgYoutube = (props) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={38} rx={19} fill="red" />
    <path
      d="M28.54 14.19a2.502 2.502 0 0 0-1.76-1.771C25.225 12 19 12 19 12s-6.226 0-7.78.419c-.856.23-1.53.91-1.76 1.771-.416 1.563-.416 4.824-.416 4.824s0 3.26.416 4.824a2.464 2.464 0 0 0 1.76 1.743C12.775 26 19 26 19 26s6.226 0 7.78-.419a2.465 2.465 0 0 0 1.76-1.743c.416-1.563.416-4.824.416-4.824s0-3.26-.416-4.823Zm-11.576 7.785v-5.921l5.204 2.96-5.204 2.96Z"
      fill="#fff"
    />
  </svg>
);
export default SvgYoutube;
