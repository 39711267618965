import * as React from "react";
const SvgFacebook = (props) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={38} rx={19} fill="#3B5998" />
    <path
      d="m24.12 20.375.61-3.982h-3.82V13.81c0-1.09.534-2.151 2.245-2.151h1.736v-3.39S23.316 8 21.81 8c-3.147 0-5.203 1.907-5.203 5.36v3.034h-3.497v3.981h3.497V30h4.304v-9.625h3.21Z"
      fill="#fff"
    />
  </svg>
);
export default SvgFacebook;
