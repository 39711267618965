import Link from "next/link";

const FooterMenu = ({ label = "", menus }) => {
    return (
        <div className="jlt-flex jlt-flex-col jlt-text-center md:jlt-text-left lg:text-left">
            <h3 className="jlt-text-[#fff] jlt-text-2xl jlt-font-bold">{label}</h3>
            <div className="jlt-mt-8 ">
                <div className="jlt-flex jlt-flex-col jlt-items-center md:jlt-items-start jlt-gap-4">
                    {menus?.map((item, idx) => (
                        <div key={idx} className="jlt-flex jlt-items-center jlt-gap-2">
                            {item?.img_url ? (
                                <div>
                                    <img
                                        src={item?.img_url}
                                        alt={item?.img_alt || "Product Logo"}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            ) : null}
                            {!!item?.menu_target ? (
                                <a
                                    className="jlt-text-sm jlt-font-semibold jlt-text-[#EAECF0] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}
                                    target="_blank">
                                    {item?.menu_label}
                                </a>
                            ) : (
                                <Link
                                    className="jlt-text-sm jlt-font-semibold jlt-text-[#EAECF0] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}>
                                    {item?.menu_label}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FooterMenu;
