import * as React from "react";
const SvgLinkedin = (props) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={38} rx={19} fill="#0077B5" />
    <path
      d="M14.029 28h-3.732V15.982h3.732V28Zm-1.868-13.657c-1.193 0-2.161-.988-2.161-2.182a2.161 2.161 0 0 1 4.322 0c0 1.194-.968 2.182-2.161 2.182ZM27.996 28h-3.724v-5.85c0-1.394-.028-3.182-1.94-3.182-1.94 0-2.238 1.514-2.238 3.081V28h-3.727V15.982h3.579v1.64h.052c.498-.945 1.715-1.941 3.531-1.941 3.777 0 4.471 2.487 4.471 5.717V28h-.004Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLinkedin;
