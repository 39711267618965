"use client";

import { getIcon } from "@litonarefin/utils/icons/index";
import { sanitize } from "@litonarefin/utils/miscellaneous";
import styled from "styled-components";
import axios from "axios";
import { handleToastr } from "@litonarefin/utils/handleToastr";

export const Subscribe = ({
    title = "",
    subTitle = "",
    type = "",
    isSimple = false,
    listID = "",
    tagID = "",
}) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let name = formData.get("name");
        let email = formData.get("email");
        name = name == null ? "" : name;

        let customFieldValues = {
            site_source: process.env.NEXT_PUBLIC_NEXTJS_SITE_URL,
        };

        let { data } = await axios.post("/api/subscribe/create", {
            first_name: name,
            email: email,
            lists: listID?.replaceAll(" ", "").split(",").map(Number),
            tags: tagID?.replaceAll(" ", "").split(",").map(Number),
            status: "yes",
            custom_values: customFieldValues,
        });

        if (data?.success) {
            let msg = "Subscribe Success!";
            handleToastr(msg, "success", 3000);
        } else {
            handleToastr(data?.message, "warning", 3000);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`${
                type === "single-post"
                    ? isSimple
                        ? ""
                        : "jlt-mt-8 jlt-rounded-lg jlt-border jlt-border-[#F2F4F7] jlt-p-10"
                    : type === "side-post"
                    ? "jlt-rounded-lg jlt-bg-white jlt-py-10 jlt-px-8 jlt-shadow-[0px_0px_40px_rgba(16,24,40,0.02)]"
                    : "jlt-w-full"
            } `}>
            {!isSimple ? (
                type === "single-post" || type === "side-post" ? (
                    <div
                        className={`jlt-flex${
                            type === "side-post"
                                ? " jlt-flex-col jlt-gap-4"
                                : " jlt-items-center jlt-gap-6"
                        }`}>
                        <span>{getIcon("openEmail")}</span>
                        <div>
                            <Title
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(title),
                                }}
                                className={type === "side-post" ? "" : "jlt-text-2xl"}
                            />
                            <SubTitle
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(subTitle),
                                }}
                                className={type === "side-post" ? "jlt-text-lg" : ""}
                            />
                        </div>
                    </div>
                ) : null
            ) : null}

            <div
                className={`${
                    type === "single-post"
                        ? "jlt-mt-8 jlt-grid jlt-grid-cols-2 jlt-items-center jlt-gap-x-2 jlt-gap-y-4"
                        : type === "side-post"
                        ? "jlt-mt-8 jlt-flex jlt-flex-col jlt-gap-2"
                        : "jlt-relative jlt-border jlt-border-[#F2F4F7] jlt-rounded-full"
                }`}>
                {type === "single-post" || type === "side-post" ? (
                    <InputField placeholder="Name" name="name" type={type} />
                ) : null}

                <InputField placeholder="Email Address" name="email" required={true} type={type} />
                <SubscribeButton
                    className={`${
                        type === "single-post" || type === "side-post"
                            ? "jlt-col-span-2 jlt-justify-center jlt-w-full"
                            : "jlt-absolute jlt-top-1 jlt-right-1"
                    }`}>
                    Subscribe {getIcon("rocket")}
                </SubscribeButton>
            </div>
        </form>
    );
};

const Title = styled.h4.attrs((props) => ({
    className: `jlt-text-[#1D2939] ${props.className}`,
}))`
    > span {
        color: #3d79f2;
    }
`;

const SubTitle = styled.h6.attrs((props) => ({
    className: "jlt-text-[#667085] jlt-mt-2 jlt-font-semibold jlt-leading-6",
}))``;

const SubscribeButton = styled.button.attrs((props) => ({
    className: `jlt-py-3 jlt-px-5 jlt-rounded-full jlt-btn jlt-font-semibold jlt-flex jlt-items-center jlt-gap-2 ${props.className}`,
}))``;

const InputField = styled.input.attrs((props) => ({
    type: "text",
    name: props.name,
    placeholder: props.placeholder,
    className: `jlt-py-4 jlt-pl-6 jlt-w-full jlt-rounded-full focus:jlt-outline-none ${
        props.type !== "" ? "jlt-border jlt-border-[#F2F4F7]" : ""
    } ${props.type === "single-post" || props.type === "side-post" ? "jlt-pr-6" : "jlt-pr-40"}`,
}))``;
